import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: 'pedido',
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./pages/order/order.component').then((c) => c.OrderComponent),
      },
      {
        path: ':id',
        loadComponent: () =>
          import('./pages/order/order.component').then((c) => c.OrderComponent),
      },
    ],
  },
];
